import React, { useRef, useState } from "react";
import Form from 'react-bootstrap/Form';
import ReCAPTCHA from "react-google-recaptcha";
import { submitFreeConsultationForm } from '../../utils/campaign';
import { addAndRemoveDisabledCls, isValidEmail, validateSubscriberData } from '../../utils/utils';

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const SideBar = () => {
  const [sideBarName, setSideBarName] = useState('');
  const [sideBarEmail, setSideBarEmail] = useState('');
  const [sideBarPhone, setSideBarPhone] = useState('');
  const [sideBarSubject, setSideBarSubject] = useState('Software Patent');
  const [sideBarMessage, setSideBarMessage] = useState('');
  //
  const captchaRef = useRef(null)
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const submitSideBar = async (e) => {
    e?.preventDefault();
    if (sideBarName && sideBarEmail && sideBarSubject && sideBarPhone && sideBarMessage) {
      // // validate email
      // if (!isValidEmail(sideBarEmail)) {
      //   alert('Email is invalid!!!');
      //   return;
      // }
      // // The number field has to have 8+ digits
      // const sideBarPhone_trim = sideBarPhone.trim();
      // const regex = /[a-zA-Z\s]/g;
      // const found = sideBarPhone_trim.match(regex);
      // if ((sideBarPhone_trim.length < 8) || (found)) {
      //   alert('The number field has to have 8+ digits');
      //   return;
      // }
      // // The message has to be 20+ characters.
      // if (sideBarMessage.trim().length < 20) {
      //   alert('The message has to be 20+ characters');
      //   return;
      // }

      //
      const token = captchaRef.current.getValue();
      if (!token) {
        alert('Please Confirm You Are Not a Robot!');
      } else {
        // check 
        const response = await fetch("https://api.trustyip.com/recaptcha/verify?token=" + token);
        const responseJson = await response.json();
        if ((responseJson.data.success) || (responseJson.data['error-codes'][0] === 'timeout-or-duplicate')) {

          // call api
          const data = {
            email: sideBarEmail,
            name: sideBarName,
            phone: sideBarPhone,
            subject: sideBarSubject,
            message: sideBarMessage,
            site: 'patentpc.com'
          }
          
          // validate data
          if (!validateSubscriberData(data)) {
            return;
          }

          // submit campagin
          submitFreeConsultationForm(data);

          // api
          fetch("https://api.trustyip.com/subcribers/add", {
            "method": "POST",
            "headers": {
              "content-type": "application/json",
              "accept": "application/json"
            },
            "body": JSON.stringify(data)
          })
            .then(response => response.json())
            .then(response => {
              alert('Thank you for contacting us. We will be getting back to you shortly.');
            })
            .catch(err => {
              alert('There is something wrong. Please try again later!');
              console.log(err);
            });
          //Reset
          captchaRef.current.reset();
          // Add disabled class
          addAndRemoveDisabledCls('sideBarBtn');
        }
      }
    } else {
      alert('Please fill in all fields.');
    }
  }
  return (
    <>
      <div className="sidebar py-4 px-3">
        <h4 className="text-center">Get A Free Consultation</h4>
        <div>
          <Form >
            <Form.Group className="mb-1" controlId="sideBarName" value={sideBarName} onChange={e => setSideBarName(e.target.value)}>
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" placeholder="Your name" />
            </Form.Group>

            <Form.Group className="mb-1" controlId="sideBarEmail" value={sideBarEmail} onChange={e => setSideBarEmail(e.target.value)}>
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" placeholder="Your email address" />
            </Form.Group>

            <Form.Group className="mb-1" controlId="sideBarPhone" value={sideBarPhone} onChange={e => setSideBarPhone(e.target.value)}>
              <Form.Label>Phone</Form.Label>
              <Form.Control type="text" placeholder="Your phone" />
            </Form.Group>


            <Form.Group className="mb-1" controlId="sideBarSubject">
              <Form.Label>What do you need help with?</Form.Label>
              <Form.Select aria-label="What do you need help with?" defaultValue={"Software Patent"} value={sideBarSubject} onChange={e => setSideBarSubject(e.target.value)}>
                <option value="Software Patent" selected="true">Software Patent</option>
                <option value="AI Patent">AI Patent</option>
                <option value="AR/VR Patent">AR/VR Patent</option>
                <option value="Communication Patent">Communication Patent</option>
                <option value="Electronic Patent">Electronic Patent</option>
                <option value="Fin Tech Patent">Fin Tech Patent</option>Fin Tech Patent
                <option value="Industrial Design Patent">Industrial Design Patent</option>
                <option value="Medical Device">Medical Device Patent</option>
                <option value="Mechanical Design">Mechanical Design Patent</option>
                <option value="Trademark">Trademark</option>
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-1" controlId="sideBarMessage" value={sideBarMessage} onChange={e => setSideBarMessage(e.target.value)}>
              <Form.Label>How can we help you with?</Form.Label>
              <Form.Control as="textarea" rows={2} placeholder="Your message" />
            </Form.Group>
            <Form.Group className='mb-1'>
              <ReCAPTCHA sitekey="6LetH-InAAAAAOJxzG4oeh59AVKhM3nj4eCxXXBI" ref={captchaRef} />
            </Form.Group>
            <span className="text-muted" style={{ fontSize: 10 + 'px' }}>By submitting your agree with our Privacy Policy</span>
            <button id="sideBarBtn" className='btn btn-warning w-100 mt-1' onClick={e => submitSideBar(e)}>Submit</button>
          </Form>
        </div>
      </div>
    </>
  )
}

export default SideBar;

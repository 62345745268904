
export function submitActiveCampaignForm(data, showAlert = true) {
  // const form = document.getElementById('_form_7_');
  // const values = formSerialize(form);
  const formData = new FormData();

  // Hidden field key/values.
  formData.append("u", "7"); // Download Your Free Patent Guide
  formData.append("f", "7"); // https://patent.activehosted.com/app/forms/7
  formData.append("s", null);
  formData.append("c", "0");
  formData.append("m", "0");
  formData.append("act", "sub");
  formData.append("v", "2");
  formData.append("or", "c0c3bf12af7fa3ad55cceb047972db9");

  // Form field key/values.
  formData.append("fullname", data.name);
  formData.append("email", data.email);
  formData.append("phone", data.phone);

  fetch(`https://patent.activehosted.com/proc.php`, {
    mode: "no-cors",
    method: 'POST',
    body: formData,
    // headers: {
    //   'Api-Token': 'f7c9c6bc056b48c06bb225bd42fba918149e90463992958b6a50b8001ef9de9cd8809bc5',
    //   'Content-Type': 'application/json'
    // }
  }).then(res => {
    if (showAlert) { alert('Thank you for subscribing to PatentPC.com!') }
  })
    // .then(data => console.log(data))
    .catch(err => {
      console.log(err)
      alert('Something wrong. Please try again later!');
    });
}

export function submitACPatentPCQuiz(data, showAlert = true) {
  // const form = document.getElementById('_form_7_');
  // const values = formSerialize(form);
  const formData = new FormData();

  // Hidden field key/values.
  formData.append("u", "39"); // Form PatentPC Quiz
  formData.append("f", "39"); // https://patent.activehosted.com/app/forms/39
  formData.append("s", null);
  formData.append("c", "0");
  formData.append("m", "0");
  formData.append("act", "sub");
  formData.append("v", "2");
  formData.append("or", "e71ff348bb080f3e4b38ac385e6bde29");

  // Form field key/values.
  formData.append("fullname", data.name);
  formData.append("email", data.email);
  formData.append("phone", data.phone);

  fetch(`https://patent.activehosted.com/proc.php`, {
    mode: "no-cors",
    method: 'POST',
    body: formData,
    // headers: {
    //   'Api-Token': 'f7c9c6bc056b48c06bb225bd42fba918149e90463992958b6a50b8001ef9de9cd8809bc5',
    //   'Content-Type': 'application/json'
    // }
  }).then(res => {
    if (showAlert) { alert('Thank you for subscribing to PatentPC.com!') }
  })
    // .then(data => console.log(data))
    .catch(err => {
      console.log(err)
      alert('Something wrong. Please try again later!');
    });
}

export function submitFreeConsultationForm(data, message = '') {
  const formData = new FormData();

  // Hidden field key/values.
  formData.append("u", "27"); // Form Get A Free Consultation
  formData.append("f", "27"); // https://patent.activehosted.com/f/27
  formData.append("s", null);
  formData.append("c", "0");
  formData.append("m", "0");
  formData.append("act", "sub");
  formData.append("v", "2");
  formData.append("or", "76ef4a4281b95723f0229c110ab30268");

  // Form field key/values.
  for (const [key, value] of Object.entries(data)) {
    let fieldName = key;
    switch(key) {
      case 'name':
        fieldName = 'fullname';
        break;
      case 'subject':
        fieldName = 'field[44]';
        break;
      case 'message':
        fieldName = 'field[45]';
        break;
      default:
        fieldName = key;
    }
    formData.append(fieldName, value);
  }

  fetch(`https://patent.activehosted.com/proc.php`, {
    mode: "no-cors",
    method: 'POST',
    body: formData,
  }).then(res => {
    if (message) { alert(message) }
  })
  .catch(err => {
    console.log(err)
    alert('Something wrong. Please try again later!');
  });
}